import React from 'react';
import { CollapsibleCard } from 'src/components/ui/collapsible-card';
import { useTranslation } from 'react-i18next';
import { cn } from 'src/lib/utils';
import { CardContent, CardHeader } from 'src/components/ui/card';
import { CollapsibleContent, CollapsibleTrigger } from 'src/components/ui/collapsible';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Button } from 'src/components/ui/button';
import {
  ArchiveRestore,
  ArchiveX,
  Blocks,
  ChevronUp,
  Loader2,
  LucideProps,
  Sparkles,
} from 'lucide-react';
import { Label } from 'src/components/ui/label';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { Badge } from 'src/components/ui/badge';
import { useDecoratedRequestOutcomeContext } from 'src/features/requests/request-outcome/use-decorated-request-outcome-context';
import { RequestOutcomePriority } from 'src/features/requests/request-outcome/request-outcome-priority';
import { Role, roles } from 'src/api/services/RoleClient';
import {
  useRequestArchivedOutcomesQueryMutator,
  useRequestPendingOutcomesQueryMutator,
} from 'src/features/requests/request-outcome/use-request-outcomes-query-mutation-decorator';
import { useMutation } from '@tanstack/react-query';
import { requestClient, requestOutcomeStatus } from 'src/lib/services/api/request-api';
import {
  ConfirmDialog,
  ConfirmDialogAction,
  ConfirmDialogCancel,
  ConfirmDialogContent,
  ConfirmDialogDescription,
  ConfirmDialogFooter,
  ConfirmDialogHeader,
  ConfirmDialogTitle,
  ConfirmDialogTrigger,
} from 'src/components/ui/confirm-dialog';
import { If } from 'src/components/If';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useRequestQueryMutationDecorator } from 'src/features/requests/use-request-query-mutation-decorator';
import {
  CollapsibleInnerCard,
  CollapsibleInnerCardContent,
  CollapsibleInnerContent,
} from 'src/components/ui/collapsible-inner-card';

export const RequestOutcomeCreatedByIcon: React.FC<
  LucideProps & {
    createdBy: Role | string;
  }
> = ({ createdBy, ...props }) => {
  switch (createdBy) {
    case roles.customer:
      return <Blocks {...props} />;
    default:
      return <Sparkles {...props} />;
  }
};

export const OutcomeCreatedByBadge: React.FC<{ createdBy: string }> = ({ createdBy }) => {
  const { t } = useTranslation();

  const createdByEditor = createdBy === 'editor';

  return (
    <Badge
      className={cn('tw-rounded-lg tw-p-2 tw-font-medium tw-text-dark ', [
        createdByEditor ? 'tw-bg-indigo-200' : 'tw-bg-neutral-300',
      ])}
      variant={'unset'}
    >
      <RequestOutcomeCreatedByIcon size={16} createdBy={createdBy} className={'tw-me-2'} />

      {t(`models/request:outcome.created_by.${createdBy}`, {
        defaultValue: 'Created by unknown',
      })}
    </Badge>
  );
};

export const RequestPendingOutcomeCard: React.FC<
  React.ComponentProps<typeof CollapsibleCard> & {
    cardTop?: React.ReactNode;
  }
> = ({ children, className, open, cardTop, ...props }) => {
  const { t } = useTranslation();
  const request = useDecoratedRequestContext();
  const requestActions = useRequestQueryMutationDecorator(request.id);
  const outcome = useDecoratedRequestOutcomeContext();

  const pendingOutcomesQueryMutator = useRequestPendingOutcomesQueryMutator(outcome.raw.parent_id);
  const archivedOutcomesQueryMutator = useRequestArchivedOutcomesQueryMutator(
    outcome.raw.parent_id,
  );

  const archiveOutcomeMutation = useMutation((id: string) => {
    return requestClient.archive(id);
  });

  const restoreOutcomeMutation = useMutation((id: string) => {
    return requestClient.restore(id);
  });

  const restore = async () => {
    restoreOutcomeMutation.mutate(outcome.id, {
      onSuccess: () => {
        requestActions.invalidate();
        archivedOutcomesQueryMutator.remove(outcome.id);
        pendingOutcomesQueryMutator.push({
          ...outcome.raw,
          status: requestOutcomeStatus.queued,
        });
      },
    });
  };

  const archive = async () => {
    archiveOutcomeMutation.mutate(outcome.id, {
      onSuccess: () => {
        requestActions.invalidate();
        pendingOutcomesQueryMutator.remove(outcome.id);
        archivedOutcomesQueryMutator.push({
          ...outcome.raw,
          status: requestOutcomeStatus.archived,
        });
      },
    });
  };

  return (
    <CollapsibleInnerCard
      open={open}
      variant={'subtle'}
      className={cn('tw-relative tw-flex tw-flex-col', className)}
      title={
        <>
          {cardTop}
          <div className={'tw-flex tw-gap-2'}>
            <RequestOutcomePriority
              size={'xs'}
              variant={outcome.raw.is_default ? 'default' : 'additional'}
            >
              {outcome.raw.sort}
            </RequestOutcomePriority>

            <div className={'tw-flex tw-grow tw-items-center'}>
              <div className={'tw-grow tw-items-center'}>
                <div className={'tw-flex tw-items-center'}>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className={'tw-line-clamp-3 tw-break-all'}>{outcome.raw.title}</div>
                      </TooltipTrigger>
                      <TooltipContent className={'tw-max-w-96 tw-overflow-x-auto tw-break-all'}>
                        {outcome.raw.title}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {...props}
    >
      <If when={archiveOutcomeMutation.isLoading || restoreOutcomeMutation.isLoading}>
        <div
          className={
            'tw-absolute tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-lg tw-bg-background/70'
          }
        >
          <Loader2 size={30} className={'tw-animate-spin tw-text-primary'} />
        </div>
      </If>

      <CollapsibleInnerContent>
        <CollapsibleInnerCardContent>
          <div className={'tw-flex tw-flex-col tw-gap-3'}>
            <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
              <span className={'tw-font-medium tw-text-text-strong'}>
                {t('attributes/request:status', {
                  defaultValue: 'Status',
                })}
              </span>
              <RequestStatusBadge variant={outcome.raw.status}>
                {t(`models/request:status.${outcome.raw.status}`, {
                  defaultValue: t('common:unknown', {
                    defaultValue: 'Unknown',
                  }),
                })}
              </RequestStatusBadge>
            </div>
          </div>

          {outcome.raw.video_template?.title && (
            <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
              <span className={'tw-font-medium tw-text-text-strong'}>{t('Template name')}</span>
              <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
                {outcome.raw.video_template?.title}
              </span>
            </div>
          )}

          <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
            <span className={'tw-font-medium tw-text-text-strong'}>
              {t('common:video_type', {
                defaultValue: 'Video type',
              })}
            </span>
            <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
              {t(`models/request:type.${outcome.raw.type}`, {
                defaultValue: t('common:unknown'),
              })}
            </span>
          </div>

          <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
            <span className={'tw-font-medium tw-text-text-strong'}>
              {t('common:total_length', {
                defaultValue: 'Total length',
              })}
            </span>
            <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
              {outcome.totalLength || t('common:unset')}
            </span>
          </div>

          {outcome.raw.aspect_ratio && (
            <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
              <span className={'tw-font-medium tw-text-text-strong'}>{t('Video size')}</span>
              <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
                {outcome.raw.aspect_ratio}
              </span>
            </div>
          )}

          <div className={'tw-flex tw-flex-col tw-gap-2 tw-py-2'}>
            <span className={'tw-font-medium tw-text-text-strong'}>
              {t('common:description', {
                defaultValue: 'Description',
              })}
            </span>
            <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
              {outcome.raw.description || t('common:unset')}
            </span>
          </div>

          <div
            className={
              'tw-flex tw-items-center tw-justify-between tw-border-t tw-border-neutral-200 tw-py-2'
            }
          >
            <div className={'tw-text-sm tw-text-text-inactive'}>
              {t(`models/request:outcome.created_by.${outcome.raw.created_by}`, {
                defaultValue: 'Created by unknown',
              })}
            </div>

            <If when={!request.isCompleted}>
              <If when={outcome.canRestore}>
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <Button
                        variant={'ghost'}
                        size={'icon'}
                        disabled={restoreOutcomeMutation.isLoading}
                        onClick={restore}
                      >
                        <ArchiveRestore size={20} />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      {t('common:restore', {
                        defaultValue: 'Restore',
                      })}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </If>

              <If when={outcome.canArchive}>
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <ConfirmDialog>
                      <TooltipTrigger asChild>
                        <ConfirmDialogTrigger asChild>
                          <Button
                            variant={'ghost'}
                            size={'icon'}
                            disabled={archiveOutcomeMutation.isLoading}
                          >
                            <ArchiveX size={20} />
                          </Button>
                        </ConfirmDialogTrigger>
                      </TooltipTrigger>
                      <ConfirmDialogContent>
                        <ConfirmDialogHeader>
                          <ConfirmDialogTitle>
                            {t('actions:archive_outcome', {
                              defaultValue: 'Archive outcome',
                            })}
                          </ConfirmDialogTitle>
                        </ConfirmDialogHeader>
                        <ConfirmDialogDescription
                          dangerouslySetInnerHTML={{
                            __html: t('alerts:confirm.archive_outcome', {
                              replace: {
                                title: outcome.raw.title,
                              },
                              defaultValue:
                                'Are you sure you want to archive <b class="tw-font-bold">:title</>?',
                            })!,
                          }}
                        />
                        <ConfirmDialogFooter>
                          <ConfirmDialogCancel>
                            {t('actions:cancel', {
                              defaultValue: 'Cancel',
                            })}
                          </ConfirmDialogCancel>
                          <ConfirmDialogAction onClick={archive}>
                            {t('actions:confirm', {
                              defaultValue: 'Confirm',
                            })}
                          </ConfirmDialogAction>
                        </ConfirmDialogFooter>
                      </ConfirmDialogContent>
                    </ConfirmDialog>
                    <TooltipContent>
                      {t('actions:archive', {
                        defaultValue: 'Archive',
                      })}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </If>
            </If>
          </div>
        </CollapsibleInnerCardContent>
        {children}
      </CollapsibleInnerContent>
    </CollapsibleInnerCard>
  );
};
